<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="menus.list"
		:total="menus.listTotal"
		:filters="filters"
		:delete="'menus'"
		:showTitle="false"
		:addButtonText="'Añadir menú'"
		:registerPrimaryKey="'id_menu'"
		:reloadByFilters="reloadByFilters"
	>
		<template #columns>
			<Column header="Título" field="titulo" />
			<Column header="Fecha">
				<template #body="slotProps">
					{{ $helper.formatDate(slotProps.data.fecha, "MM/YYYY") }}
				</template>
			</Column>
			<Column header="Centro">
				<template #body="slotProps">
					<div class="d-flex">
						{{ slotProps.data.center.nombre }}
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";

	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "titulo",
						value: null,
						type: "string",
						placeholder: "Título",
					},
					{
						name: "mes",
						value: null,
						type: "dropdown",
						placeholder: "Mes",
						options: [
							{
								label: "Enero",
								value: 1,
							},
							{
								label: "Febrero",
								value: 2,
							},
							{
								label: "Marzo",
								value: 3,
							},
							{
								label: "Abril",
								value: 4,
							},
							{
								label: "Mayo",
								value: 5,
							},
							{
								label: "Junio",
								value: 6,
							},
							{
								label: "Julio",
								value: 7,
							},
							{
								label: "Agosto",
								value: 8,
							},
							{
								label: "Septiembre",
								value: 9,
							},
							{
								label: "Octubre",
								value: 10,
							},
							{
								label: "Noviembre",
								value: 11,
							},
							{
								label: "Diciembre",
								value: 12,
							},
						],
					},
					{
						name: "anio",
						value: null,
						type: "string",
						placeholder: "Año",
					},
					{
						name: "id_centro",
						value: null,
						type: "dropdown",
						placeholder: "Centro",
						options: [],
					},
				],
				reloadByFilters: false,
			};
		},
		computed: {
			...mapState(["menus", "centers"]),
		},
		methods: {
			...mapActions(["getRegisters"]),
		},
		mounted() {
			this.getRegisters({
				route: "centers",
				stateVariable: "centers",
			}).then(() => {
				this.reloadByFilters = true;
			});
		},
		watch: {
			"centers.list": function (val) {
				let options = [];

				val.map((center) => {
					options.push({
						label: center.nombre,
						value: center.id_centro,
					});
				});

				this.filters[3].options = options;
			},
		},
	};
</script>
