<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="centers.list"
		:total="centers.listTotal"
		:filters="filters"
		:delete="'centers'"
		:showTitle="false"
		:addButtonText="'Añadir centro'"
		:showSelectMultipleColumn="false"
		:registerPrimaryKey="'id_centro'"
	>
		<template #columns>
			<Column header="Nombre" field="nombre" />
			<Column header="Dirección" field="direccion"></Column>
			<Column header="Teléfono" field="telefono"></Column>
			<Column header="Email" field="email"></Column>
			<Column header="Chat">
				<template #body="slotProps">
					<div class="d-flex">
						<div
							class="px-3 py-2 text-white border-radius"
							:class="[
								slotProps.data.status_chat
									? 'bg-secondary'
									: 'bg-light-primary',
							]"
						>
							{{ slotProps.data.status_chat ? "Sí" : "No" }}
						</div>
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";

	import { mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "nombre",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "direccion",
						value: null,
						type: "string",
						placeholder: "Dirección",
					},
					{
						name: "telefono",
						value: null,
						type: "string",
						placeholder: "Teléfono",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
				],
			};
		},
		computed: {
			...mapState(["centers"]),
		},
	};
</script>
