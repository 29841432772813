<template>
	<div>
		<ReportsTable :route="route" :stateVariable="stateVariable" />
		<ReportsDialog :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import ReportsTable from "./ReportsTable.vue";
	import ReportsDialog from "./ReportsDialog.vue";

	export default {
		components: {
			ReportsTable,
			ReportsDialog,
		},
		data() {
			return {
				route: "reports",
				stateVariable: "reports",
			};
		},
	};
</script>
