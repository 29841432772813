import Consts from "../consts";

const defaults = [
	"centers",
	"instructors",
	"fathers",
	"students",
	"menus",
	"communications",
	"reports",
];

const state = {
	baseURL: Consts.defaultApiRoute,
	showSidebar: false,
	userLogged: null,
	showUpdatePassword: false,
	updatePasswordDialog: false,

	// Datatable defaults
	datatableDefaults: {
		noResults: "No hay resultados",
		lazy: true,
		loading: true,
		paginator: true,
		rows: 5,
		rowsPerPageOptions: [5, 10, 20],
		sortField: "created_at",
		sortOrder: "desc",
		rowHover: true,
		scrollable: true,
		scrollHeight: "600px",
		currentPage: 0,
		paginatorTemplate:
			"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
		currentPageReportTemplate:
			"Mostrando del {first} al {last} de un total de {totalRecords} registros",
		responsiveLayout: "stack",
		breakpoint: "960px",
	},

	// Dialogs defaults
	dialogDefaults: {
		modal: true,
		closeOnEscape: true,
		dismissableMask: true,
	},
};

for (const item of defaults) {
	state[item] = {
		// Table
		list: [],
		listTotal: 0,
		currentPage: 0,
		rows: 5,
		sort: {
			field: "id",
			order: -1,
		},

		// Form
		register: null,
		dialog: false,
		dialogMode: "show",
	};
}

state.reportsResponses = null;

export default state;
