<template>
	<div>
		<div v-if="binary" class="d-flex align-items-center gap-2">
			<Checkbox v-model="model" :disabled="disabled" :binary="true" />
			<label class="fw-bold"
				><a :href="url" target="_blank">{{ label }}</a></label
			>
		</div>

		<div v-else>
			<label class="text-primary fw-bold">{{ label }}</label>
			<div
				v-for="(value, index) in values"
				:key="index"
				class="d-flex align-items-center gap-2"
			>
				<Checkbox
					v-model="model"
					:disabled="disabled"
					:name="name"
					:value="value.value"
				/>
				<label for="input" class="fw-bold"
					><a :href="url" target="_blank">{{ value.label }}</a></label
				>
			</div>
		</div>
		<div v-if="error != null" :class="errorClass">
			{{ error }}
		</div>
	</div>
</template>

<script>
	import Checkbox from "primevue/checkbox";

	export default {
		components: {
			Checkbox,
		},
		props: {
			label: {
				type: String,
				required: true,
			},
			error: {
				type: String,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			name: {
				type: String,
				required: false,
			},
			values: {
				type: Array,
				required: false,
			},
			binary: {
				type: Boolean,
				default: false,
			},
			errorClass: {
				type: String,
				default: "text-danger",
			},
			url: {
				type: String,
				required: false,
			},
		},
		data: () => ({
			model: null,
		}),
		watch: {
			model(newValue) {
				this.$emit("change-value", newValue);
			},
		},
	};
</script>
