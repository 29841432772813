<template>
	<Dialog
		v-model:visible="$store.state.communications.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12">
					<FileUpload
						label="Subir archivo"
						ref="FileUpload"
						:error="form.errors.get('files_list')"
						:disabled="disabled"
						:old_file_id="communications.register?.id_comunicacion"
						:download_old_file_route="`/communications/${communications.register?.id_comunicacion}/download`"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="titulo"
						label="Título"
						:disabled="disabled"
						:error="form.errors.get('titulo')"
						@change-value="(value) => (form.titulo = value)"
					/>
				</div>
				<div class="col-12">
					<DropDown
						ref="id_centro"
						label="Centro"
						:options="centers.list"
						optionValue="id_centro"
						optionLabel="nombre"
						:displayText="'nombre'"
						:disabled="disabled"
						:error="form.errors.get('id_centro')"
						@change-value="(value) => (form.id_centro = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							show: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "comunicado",
			title: `Añadir comunicado`,
			disabled: false,
			lastPageChange: null,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.communications.register != null;
				const url = `/communications${
					update ? `/${this.communications.register.id_comunicacion}` : ""
				}`;

				this.form.files_list = this.$refs.FileUpload.files;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.communications.currentPage,
							rows: this.communications.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				this.getRegisters({
					route: "/fathers",
					stateVariable: "fathers",
					getAll: true,
					showLoading: false,
				}).then(() => {
					this.getRegisters({
						route: "/instructors",
						stateVariable: "instructors",
						getAll: true,
						showLoading: false,
					}).then(() => {
						const register = this.communications.register;

						if (register != null) {
							for (const key in register) {
								if (
									Object.hasOwnProperty.call(register, key) &&
									this.$refs[key] != undefined
								) {
									this.$refs[key].model = register[key];
								}
							}

							if (this.communications.dialogMode == "edit") {
								this.title = `Editar ${this.modelName}`;
								this.disabled = false;
							} else {
								this.title = `Ver ${this.modelName}`;
								this.disabled = true;
							}
						}
					});
				});
			},
		},
		computed: {
			...mapState([
				"dialogDefaults",
				"communications",
				"centers",
				"fathers",
				"instructors",
			]),
		},
	};
</script>
