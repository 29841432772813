<template>
	<div>
		<MenusTable :route="route" :stateVariable="stateVariable" />
		<MenusForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import MenusTable from "./MenusTable.vue";
	import MenusForm from "./MenusForm.vue";

	export default {
		components: {
			MenusTable,
			MenusForm,
		},
		data() {
			return {
				route: "menus",
				stateVariable: "menus",
			};
		},
	};
</script>
