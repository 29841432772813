<template>
	<div>
		<StudentsTable :route="route" :stateVariable="stateVariable" />
		<StudentsForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import StudentsTable from "./StudentsTable.vue";
	import StudentsForm from "./StudentsForm.vue";

	export default {
		components: {
			StudentsTable,
			StudentsForm,
		},
		data() {
			return {
				route: "students",
				stateVariable: "students",
			};
		},
	};
</script>
