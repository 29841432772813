<template>
	<div>
		<CommunicationsTable :route="route" :stateVariable="stateVariable" />
		<CommunicationsForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import CommunicationsTable from "./CommunicationsTable.vue";
	import CommunicationsForm from "./CommunicationsForm.vue";

	export default {
		components: {
			CommunicationsTable,
			CommunicationsForm,
		},
		data() {
			return {
				route: "communications",
				stateVariable: "communications",
			};
		},
	};
</script>
