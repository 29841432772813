<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="communications.list"
		:total="communications.listTotal"
		:filters="filters"
		:delete="'communications'"
		:showTitle="false"
		:addButtonText="'Añadir comunicado'"
		:registerPrimaryKey="'id_comunicacion'"
		:reloadByFilters="reloadByFilters"
	>
		<template #columns>
			<Column header="Título" field="titulo" />
			<Column header="Centro">
				<template #body="slotProps">
					<div class="d-flex">
						{{ slotProps.data.center.nombre }}
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";

	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "titulo",
						value: null,
						type: "string",
						placeholder: "Título",
					},

					{
						name: "id_centro",
						value: null,
						type: "dropdown",
						placeholder: "Centro",
						options: [],
					},
				],
				reloadByFilters: false,
			};
		},
		computed: {
			...mapState(["communications", "centers"]),
		},
		methods: {
			...mapActions(["getRegisters"]),
		},
		mounted() {
			this.getRegisters({
				route: "centers",
				stateVariable: "centers",
			}).then(() => {
				this.reloadByFilters = true;
			});
		},
		watch: {
			"centers.list": function (val) {
				let options = [];

				val.map((center) => {
					options.push({
						label: center.nombre,
						value: center.id_centro,
					});
				});

				this.filters[1].options = options;
			},
		},
	};
</script>
