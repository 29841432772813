<template>
	<div class="w-100 d-flex flex-column">
		<div v-for="(item, index) in items" :key="index" class="mb-2">
			<div v-if="!item.items">
				<div class="px-3">
					<Link :item="item" @click="toggleSidebar(false)" />
				</div>
			</div>
			<div v-else>
				<Accordion @click="item.right_down = !item.right_down">
					<AccordionTab>
						<template #header>
							<div
								class="link w-100 d-flex align-items-center justify-content-between px-3 py-3 p-ripple"
								v-ripple
							>
								<div class="d-flex align-items-center gap-2">
									<i :class="item.icon"></i>
									<p class="mb-0">{{ item.label }}</p>
								</div>
								<i
									class="pi"
									:class="[
										item.right_down ? 'pi-chevron-down' : 'pi-chevron-right',
									]"
								></i>
							</div>
						</template>
						<div class="d-flex flex-column">
							<div v-for="(subItem, subIndex) in item.items" :key="subIndex">
								<Link
									class="ps-5"
									:item="subItem"
									@click="toggleSidebar(false)"
								/>
							</div>
						</div>
					</AccordionTab>
				</Accordion>
			</div>
		</div>
	</div>
</template>

<script>
	import Accordion from "primevue/accordion";
	import AccordionTab from "primevue/accordiontab";

	import Link from "./Link.vue";
	import { mapMutations } from "vuex";

	export default {
		components: {
			Accordion,
			AccordionTab,
			Link,
		},
		data() {
			return {
				items: [
					{
						label: "Centros",
						href: "/admin/centers",
					},
					{
						label: "Monitores",
						href: "/admin/instructors",
					},
					{
						label: "Padres y alumnos",
						href: "/admin/fathers-and-students",
					},
					{
						label: "Menús",
						href: "/admin/menus",
					},
					{
						label: "Comunicados",
						href: "/admin/communications",
					},
					{
						label: "Informes",
						href: "/admin/reports",
					},
				],
			};
		},
		methods: {
			...mapMutations(["toggleSidebar"]),
			setActiveLink() {
				this.items.forEach((item) => {
					item.active = item.href == this.$route.path;
				});
			},
		},
		mounted() {
			this.setActiveLink();
		},
		watch: {
			$route() {
				this.setActiveLink();
			},
		},
	};
</script>
