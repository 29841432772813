<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="instructors.list"
		:total="instructors.listTotal"
		:filters="filters"
		:delete="'instructors'"
		:showTitle="false"
		:addButtonText="'Añadir monitor'"
		:registerPrimaryKey="'id_monitor'"
		:reloadByFilters="reloadByFilters"
	>
		<template #columns>
			<Column header="Nombre" field="nombre" />
			<Column header="Apellidos">
				<template #body="slotProps">
					<div class="d-flex">
						{{ slotProps.data.apellido_1 }}
						{{ slotProps.data.apellido_2 }}
					</div>
				</template>
			</Column>
			<Column header="Teléfono" field="telefono"></Column>
			<Column header="Email" field="email"></Column>
			<Column header="Centro">
				<template #body="slotProps">
					<div class="d-flex">
						{{ slotProps.data.center.nombre }}
					</div>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";

	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "nombre",
						value: null,
						type: "string",
						placeholder: "Nombre",
					},
					{
						name: "apellidos",
						value: null,
						type: "string",
						placeholder: "Apellidos",
					},
					{
						name: "telefono",
						value: null,
						type: "string",
						placeholder: "Teléfono",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Email",
					},
					{
						name: "id_centro",
						value: null,
						type: "dropdown",
						placeholder: "Centro",
						options: [],
					},
				],
				reloadByFilters: false,
			};
		},
		methods: {
			...mapActions(["getRegisters"]),
		},
		mounted() {
			this.getRegisters({
				route: "centers",
				stateVariable: "centers",
			}).then(() => {
				this.reloadByFilters = true;
			});
		},
		watch: {
			"centers.list": function (val) {
				let options = [];

				val.map((center) => {
					options.push({
						label: center.nombre,
						value: center.id_centro,
					});
				});

				this.filters[4].options = options;
			},
		},
		computed: {
			...mapState(["instructors", "centers"]),
		},
	};
</script>
