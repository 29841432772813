<template>
	<Dialog
		v-model:visible="$store.state.reports.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12">
					<InputText
						ref="alumno"
						label="Alumno"
						:disabled="disabled"
						:error="form.errors.get('alumno')"
						@change-value="(value) => (form.alumno = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="monitor"
						label="Monitor"
						:disabled="disabled"
						:error="form.errors.get('monitor')"
						@change-value="(value) => (form.monitor = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="centro"
						label="Centro"
						:disabled="disabled"
						:error="form.errors.get('centro')"
						@change-value="(value) => (form.centro = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="fecha"
						label="Fecha"
						:disabled="disabled"
						:error="form.errors.get('fecha')"
						@change-value="(value) => (form.fecha = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre1"
						label="Cumple las normas de higiene"
						:disabled="disabled"
						:error="form.errors.get('pre1')"
						@change-value="(value) => (form.pre1 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre2"
						label="Su postura en la mesa es correcta"
						:disabled="disabled"
						:error="form.errors.get('pre2')"
						@change-value="(value) => (form.pre2 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre3"
						label="Utiliza correctamente los cubiertos"
						:disabled="disabled"
						:error="form.errors.get('pre3')"
						@change-value="(value) => (form.pre3 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre4"
						label="Es educado con sus compañeros"
						:disabled="disabled"
						:error="form.errors.get('pre4')"
						@change-value="(value) => (form.pre4 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre5"
						label="Obedece a los monitores"
						:disabled="disabled"
						:error="form.errors.get('pre5')"
						@change-value="(value) => (form.pre5 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre6"
						label="Acepta la variedad de los alimentos"
						:disabled="disabled"
						:error="form.errors.get('pre6')"
						@change-value="(value) => (form.pre6 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="pre7"
						label="Participa en las actividades del patio"
						:disabled="disabled"
						:error="form.errors.get('pre7')"
						@change-value="(value) => (form.pre7 = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="observaciones"
						label="Observaciones"
						:disabled="disabled"
						:error="form.errors.get('observaciones')"
						@change-value="(value) => (form.observaciones = value)"
					/>
				</div>
			</div>
		</form>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapMutations, mapState } from "vuex";

	export default {
		props: {
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			title: `Ver informe`,
			disabled: true,
		}),
		methods: {
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			hide() {
				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.reports.register;

				this.$refs.alumno.model = `${register.student.nombre} ${register.student.apellido_1} ${register.student.apellido_2}`;
				this.$refs.monitor.model = `${register.instructor.nombre} ${register.instructor.apellido_1} ${register.instructor.apellido_2}`;
				this.$refs.observaciones.model = register.observaciones;

				if (register.student.center != null) {
					this.$refs.centro.model = register.student.center.nombre;
				} else {
					this.$refs.centro.model = register.instructor.center.nombre;
				}

				this.$refs.fecha.model = this.$helper.formatDate(
					register.fecha,
					"MM-YYYY"
				);

				for (let index = 1; index < 8; index++) {
					this.$refs[`pre${index}`].model = this.reportsResponses.filter(
						(response) => response.resp_int == register[`pre${index}`]
					)[0].resp_str;
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "reports", "reportsResponses"]),
		},
	};
</script>
