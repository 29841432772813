<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-white title">Reestablece tu contraseña</div>
			<p
				@click="$parent.showForm = 'login'"
				class="cursor-pointer text-primary"
			>
				<b>Volver al inicio de sesión</b>
			</p>
			<div class="col-12">
				<InputPassword
					label="Contraseña"
					:error="form.errors.get('password')"
					@change-value="(value) => (form.password = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Repetir contraseña"
					:error="form.errors.get('password_confirm')"
					:feedback="false"
					@change-value="(value) => (form.password_confirm = value)"
					:errorClass="'text-white'"
				/>
			</div>
			<div class="col-12 d-flex mt-4">
				<Button
					@click="save()"
					label="Actualizar"
					class="w-100"
					:disabled="form.busy"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data: () => ({
			form: new Form(),
		}),
		methods: {
			...mapActions(["sendForm"]),
			save() {
				const url = `/reset-password`;

				this.form.token = this.$route.params.token;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.$parent.showRegisterAlert();

						this.$nextTick(() => {
							this.$parent.showForm = "login";
						});
					} else {
						this.$parent.showError();
					}
				});
			},
		},
	};
</script>
