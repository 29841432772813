<template>
	<TabView lazy>
		<TabPanel header="Padres">
			<Fathers />
		</TabPanel>
		<TabPanel header="Alumnos">
			<Students />
		</TabPanel>
	</TabView>
</template>

<script>
	import TabView from "primevue/tabview";
	import TabPanel from "primevue/tabpanel";

	import Fathers from "@/components/fathers/Fathers.vue";
	import Students from "@/components/students/Students.vue";

	export default {
		components: {
			TabView,
			TabPanel,
			Fathers,
			Students,
		},
	};
</script>
