import { createWebHistory, createRouter } from "vue-router";

import axios from "./axios";
import store from "./store";

// Layouts
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import AdminLayout from "@/components/layouts/AdminLayout.vue";

// Admin
import Centers from "@/components/centers/Centers.vue";
import Instructors from "@/components/instructors/Instructors.vue";
import FathersAndStudents from "@/components/fathers-and-students/FathersAndStudents.vue";
import Menus from "@/components/menus/Menus.vue";
import Communications from "@/components/communications/Communications.vue";
import Reports from "@/components/reports/Reports.vue";

const routes = [
	{
		path: "/",
		component: AuthLayout,
		beforeEnter: checkLogin,
		name: "home",
	},
	{
		path: "/login",
		component: AuthLayout,
		beforeEnter: checkLogin,
		name: "login",
	},
	{
		path: "/password/reset/:token",
		component: AuthLayout,
		name: "PasswordReset",
	},
	{
		path: "/admin",
		component: AdminLayout,
		beforeEnter: checkUserLogged,
		children: [
			{
				path: "centers",
				name: "Centros",
				component: Centers,
				meta: {
					title: "Centros",
				},
			},
			{
				path: "instructors",
				name: "Monitores",
				component: Instructors,
				meta: {
					title: "Monitores",
				},
			},
			{
				path: "fathers-and-students",
				name: "Padres y alumnos",
				component: FathersAndStudents,
				meta: {
					title: "Padres y alumnos",
				},
			},
			{
				path: "menus",
				name: "Menús",
				component: Menus,
				meta: {
					title: "Menús",
				},
			},
			{
				path: "communications",
				name: "Comunicados",
				component: Communications,
				meta: {
					title: "Comunicados",
				},
			},
			{
				path: "reports",
				name: "Informes",
				component: Reports,
				meta: {
					title: "Informes",
				},
			},
		],
	},
	{
		path: "/password/reset/:token",
		component: AuthLayout,
		name: "PasswordReset",
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/",
	},
];

function checkLogin(to, from, next) {
	if (localStorage.getItem("accessToken") != null) {
		next({ path: "/admin/centers" });
	} else {
		next();
	}

	return;
}

function checkUserLogged(to, from, next) {
	if (localStorage.getItem("accessToken") != null) {
		axios.defaults.headers.common[
			"Authorization"
		] = `Bearer ${localStorage.getItem("accessToken")}`;

		axios
			.post("/check-user-logged")
			.then((response) => {
				const user = response.data.user;

				store.commit("changeUserLogged", user);
				next();
			})
			.catch((error) => {
				console.error(
					"Router JS ~ checkAdminRights",
					error.response,
					error.response?.data?.message
				);

				if (error.response.data.message === "CSRF token mismatch.") {
					location.reload();
				}

				if (
					error.response.data.message == "User not found" ||
					error.response.data.message == "Unauthorized" ||
					error.response.data.message == "Unauthenticated."
				) {
					window.localStorage.clear();
					next({ path: "/login" });
				}
			});

	} else {
		next({ path: "/login" });
	}
}

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// Get the page title from the route meta data that we have defined
	// See further down below for how we setup this data
	const title = to.meta.title;

	//Take the title from the parameters
	const titleFromParams = to.params.pageTitle;

	// If the route has a title, set it as the page title of the document/page
	if (title) {
		document.title = title;
	}

	// If we have a title from the params, extend the title with the title
	// from our params
	if (titleFromParams) {
		document.title = `${titleFromParams} - ${document.title}`;
	}

	// Continue resolving the route
	next();
});

export default router;
