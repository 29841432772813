<template>
	<div>
		<InstructorsTable :route="route" :stateVariable="stateVariable" />
		<InstructorsForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import InstructorsTable from "./InstructorsTable.vue";
	import InstructorsForm from "./InstructorsForm.vue";

	export default {
		components: {
			InstructorsTable,
			InstructorsForm,
		},
		data() {
			return {
				route: "instructors",
				stateVariable: "instructors",
			};
		},
	};
</script>
