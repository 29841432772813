const mutations = {
	// General
	changeUserLogged(state, n) {
		state.userLogged = n;
	},
	changeUserLoggedName(state, n) {
		state.userLogged.name = n;
	},
	toggleSidebar(state, n) {
		state.showSidebar = n;
	},
	toggleUpdatePassword(state, n) {
		state.showUpdatePassword = n;
	},
	toggleUpdatePasswordDialog(state, n) {
		state.updatePasswordDialog = n;
	},

	// Toasts
	changeSuccessToast(state, n) {
		state.successToast = n;
	},
	changeErrorToast(state, n) {
		state.errorToast = n;
	},
	changeWarningToast(state, n) {
		state.warningToast = n;
	},

	// Defaults
	changeCurrentTablePage(state, n) {
		state[n.stateVariable].currentPage = n.event.page;
		state[n.stateVariable].rows = n.event.rows;
	},
	changeCurrentTableSort(state, n) {
		state[n.stateVariable].sort.field = n.event.sortField;
		state[n.stateVariable].sort.order = n.event.sortOrder;
	},
	toggleFormDialog(state, n) {
		state[n.stateVariable].dialog = n.show;
	},
	changeFormDialogMode(state, n) {
		state[n.stateVariable].dialogMode = n.dialogMode;
	},
	changeCurrentRegister(state, n) {
		state[n.stateVariable].register = n.register;
	},

	// Users
	setUserLoggedUpdatedPassword(state) {
		state.userLogged.has_updated_password = true;
	},
};

export default mutations;
