<template>
	<Dialog
		v-model:visible="$store.state.students.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '30vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-3">
				<div class="col-12">
					<InputText
						ref="nombre"
						label="Nombre"
						:disabled="disabled"
						:error="form.errors.get('nombre')"
						@change-value="(value) => (form.nombre = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="apellido_1"
						label="Primer apellido"
						:disabled="disabled"
						:error="form.errors.get('apellido_1')"
						@change-value="(value) => (form.apellido_1 = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="apellido_2"
						label="Segundo apellido"
						:disabled="disabled"
						:error="form.errors.get('apellido_2')"
						@change-value="(value) => (form.apellido_2 = value)"
					/>
				</div>
				<div class="col-12">
					<DatePicker
						ref="fecha_nacimiento"
						label="Fecha de nacimiento"
						:disabled="disabled"
						:error="form.errors.get('fecha_nacimiento')"
						@change-value="(value) => (form.fecha_nacimiento = value)"
						:showTime="false"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="alergias"
						label="Alergias"
						:disabled="disabled"
						:error="form.errors.get('alergias')"
						@change-value="(value) => (form.alergias = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="intolerancias"
						label="Intolerancias"
						:disabled="disabled"
						:error="form.errors.get('intolerancias')"
						@change-value="(value) => (form.intolerancias = value)"
					/>
				</div>
				<div class="col-12">
					<TextArea
						ref="otros_datos"
						label="Otros datos"
						:disabled="disabled"
						:error="form.errors.get('otros_datos')"
						@change-value="(value) => (form.otros_datos = value)"
					/>
				</div>
				<div class="col-12">
					<DropDown
						ref="id_centro"
						label="Centro"
						:options="centers.list"
						optionValue="id_centro"
						optionLabel="nombre"
						:displayText="'nombre'"
						:disabled="disabled"
						:error="form.errors.get('id_centro')"
						@change-value="(value) => (form.id_centro = value)"
					/>
				</div>
				<div class="col-12">
					<MultiSelect
						ref="padres"
						label="Padres"
						:options="fathers.list"
						:optionValue="'id_padre'"
						optionLabel="nombre"
						:displayText="['nombre', 'apellido_1']"
						displayTextSeparator=" "
						:disabled="disabled"
						:error="form.errors.get('padres')"
						@change-value="(value) => (form.padres = value)"
					/>
				</div>
				<div class="col-12">
					<DropDown
						ref="id_monitor"
						label="Monitor"
						:options="instructors.list"
						optionValue="id_monitor"
						optionLabel="nombre"
						:displayText="['nombre', 'apellido_1']"
						:disabled="disabled"
						:error="form.errors.get('id_monitor')"
						@change-value="(value) => (form.id_monitor = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							show: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border border-primary text-primary"
				/>
				<Button @click="save()" label="Guardar" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "alumno",
			title: `Añadir alumno`,
			disabled: false,
			lastPageChange: null,
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.students.register != null;
				const url = `/students${
					update ? `/${this.students.register.id_alumno}` : ""
				}`;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.students.currentPage,
							rows: this.students.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.disabled = false;

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();
				this.getRegisters({
					route: "/fathers",
					stateVariable: "fathers",
					getAll: true,
					showLoading: false,
					is_select: true,
					id_centro: this.students.register.id_centro
				}).then(() => {
					this.getRegisters({
						route: "/instructors",
						stateVariable: "instructors",
						getAll: true,
						showLoading: false,
						is_select: true,
						id_centro: this.students.register.id_centro
					}).then(() => {
						const register = this.students.register;

						if (register != null) {
							for (const key in register) {
								if (
									Object.hasOwnProperty.call(register, key) &&
									this.$refs[key] != undefined
								) {
									this.$refs[key].model = register[key];
								}
							}

							const fathers = [];

							register.fathers.forEach((father) => {
								this.fathers.list.find((f) => {
									if (f.id_padre == father.id_padre) {
										fathers.push(f.id_padre);
									}
								});
							});

							this.$refs.padres.model = fathers;

							if (this.students.dialogMode == "edit") {
								this.title = `Editar ${this.modelName}`;
								this.disabled = false;
							} else {
								this.title = `Ver ${this.modelName}`;
								this.disabled = true;
							}
						}
					});
				});
			},
		},
		computed: {
			...mapState([
				"dialogDefaults",
				"students",
				"centers",
				"fathers",
				"instructors",
			]),
		},
	};
</script>
