<template>
	<div>
		<FathersTable :route="route" :stateVariable="stateVariable" />
		<FathersForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import FathersTable from "./FathersTable.vue";
	import FathersForm from "./FathersForm.vue";

	export default {
		components: {
			FathersTable,
			FathersForm,
		},
		data() {
			return {
				route: "fathers",
				stateVariable: "fathers",
			};
		},
	};
</script>
