<template>
	<router-link
		to="/admin/users"
		class="logo d-flex align-items-center justify-content-center"
	>
		<img src="@/assets/images/logo.png" />
	</router-link>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.logo {
		position: sticky;
		left: 0;
		top: 0;
		z-index: 50;
		height: 20%;

		img {
			width: 230px;
			max-width: 90%;
		}

		@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
			img {
				width: 150px;
			}
		}
	}
</style>
