<template>
	<div>
		<CentersTable :route="route" :stateVariable="stateVariable" />
		<CentersForm :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import CentersTable from "./CentersTable.vue";
	import CentersForm from "./CentersForm.vue";

	export default {
		components: {
			CentersTable,
			CentersForm,
		},
		data() {
			return {
				route: "centers",
				stateVariable: "centers",
			};
		},
	};
</script>
