<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="reports.list"
		:total="reports.listTotal"
		:filters="filters"
		:delete="'reports'"
		:showTitle="false"
		:showAddButton="false"
		:showEditButton="false"
		:registerPrimaryKey="'id_informe'"
		:reloadByFilters="reloadByFilters"
	>
		<template #columns>
			<Column header="Alumno">
				<template #body="slotProps">
					<div v-if="slotProps.data.student" class="d-flex">
						{{ slotProps.data.student.nombre }}
						{{ slotProps.data.student.apellido_1 }}
						{{ slotProps.data.student.apellido_2 }}
					</div>
					<div v-else class="text-muted">Sin alumno</div>
				</template>
			</Column>
			<Column header="Monitor">
				<template #body="slotProps">
					<div v-if="slotProps.data.instructor" class="d-flex">
						{{ slotProps.data.instructor.nombre }}
						{{ slotProps.data.instructor.apellido_1 }}
						{{ slotProps.data.instructor.apellido_2 }}
					</div>
					<div v-else class="text-muted">Sin monitor</div>
				</template>
			</Column>
			<Column header="Centro">
				<template #body="slotProps">
					<div v-if="slotProps.data.student" class="d-flex">
						{{ slotProps.data.student.center.nombre }}
					</div>
					<div v-else-if="slotProps.data.instructor" class="d-flex">
						{{ slotProps.data.instructor.center.nombre }}
					</div>
					<div v-else class="text-muted">Sin centro</div>
				</template>
			</Column>
			<Column header="Fecha">
				<template #body="slotProps">
					<div class="d-flex">
						{{ $helper.formatDate(slotProps.data.fecha, "MM-YYYY") }}
					</div>
				</template>
			</Column>
			<Column header="Observaciones">
				<template #body="slotProps">
					<LongTextColumn :data="slotProps.data.observaciones" />
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";

	import { mapActions, mapState } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "alumno",
						value: null,
						type: "string",
						placeholder: "Alumno",
					},
					{
						name: "monitor",
						value: null,
						type: "string",
						placeholder: "Monitor",
					},
					{
						name: "id_centro",
						value: null,
						type: "dropdown",
						placeholder: "Centro",
						options: [],
					},
				],
				reloadByFilters: false,
			};
		},
		computed: {
			...mapState(["reports", "centers"]),
		},
		methods: {
			...mapActions(["getRegisters", "getReportsResponses"]),
		},
		mounted() {
			this.getReportsResponses();

			this.getRegisters({
				route: "centers",
				stateVariable: "centers",
			}).then(() => {
				this.reloadByFilters = true;
			});
		},
		watch: {
			"centers.list": function (val) {
				let options = [];

				val.map((center) => {
					options.push({
						label: center.nombre,
						value: center.id_centro,
					});
				});

				this.filters[2].options = options;
			},
		},
	};
</script>
